<section class="relative text-gray-600 bg-gray-100 body-font" id="pricing-first">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-col text-center">
                <div class="max-w-prose mx-auto">
                    <h1 class="title-font text-gray-800 sm:text-4xl text-3xl mb-4 font-medium max-w-md">
                        Preise
                    </h1>
                </div>
                <p class="mx-auto max-w-prose">
                    Lukaz bietet flexible, transparente Preisgestaltung, die es Schulen jedes Budgets ermöglicht, mit KI durchzustarten.
                </p>
            </div>
        </div>
    </div>
    <div class="container px-5 mx-auto">
        <div class="flex flex-col">
            <div class="mx-auto">
                <div class="container mx-auto flex md:flex-row flex-col align-top">
                    <div class="p-4">
                        <div class="h-full p-6 bg-white dark:bg-gray-900 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                            <h2 class="text-sm tracking-widest title-font mb-1 font-medium">STARTER</h2>
                            <h1 class="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Gratis</h1>
                            <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Gemini & Llama
                            </p>
                            <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Vorlagen
                            </p>
                            <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Gruppen
                            </p>
                            <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>PDF Export
                            </p>
                            <a class="flex items-center mt-auto text-white dark:text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded"
                               href="https://lukaz.ai" target="_blank">
                                Konto erstellen
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </a>
                            <p class="text-xs text-gray-500 mt-3">* Aufladen von KI-Guthaben mglw. erforderlich.</p>
                        </div>
                    </div>
                    <div class="p-4">
                        <div class="h-full p-6 bg-white dark:bg-gray-900 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
                            <span class="bg-indigo-500 text-white dark:text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">BELIEBT</span>
                            <h2 class="text-sm tracking-widest title-font mb-1 font-medium">PRO</h2>
                            <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                                <span>5 €</span>
                                <span class="text-lg ml-1 font-normal text-gray-500">/Jahr</span>
                            </h1>
                            <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Alle KI-Modelle
                            </p>
                            <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Eigene Datenquellen
                            </p>
                            <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Interaktiver Unterricht
                            </p>
                            <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Bild- & Audiogenerierung
                            </p>
                            <a class="flex items-center mt-auto text-white dark:text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
                               href="https://license.lukaz.ai" target="_blank">
                                Jetzt buchen
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </a>
                            <p class="text-xs text-gray-500 mt-3">* Jährlicher Preis pro Benutzer ab 100 Lizenzen.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container px-5 py-12 mx-auto">
        <div class="flex flex-col text-center">
            <div class="max-w-prose mx-auto">
                <h2 class="title-font text-gray-800 sm:text-3xl mb-4 text-2xl font-medium max-w-md">
                    Didacta 2025 - Sonderangebot
                </h2>
            </div>
            <p class="mx-auto max-w-prose">
                Falls das Kontingent Ihrer Lizenzen bereits verbraucht wurde, können KI-Guthaben immer aufgeladen werden.
            </p>
        </div>
    </div>

    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top">
        <table class="table-auto w-prose mx-auto text-left whitespace-no-wrap bg-white dark:bg-gray-900 rounded-md">
            <thead>
            <tr>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Lizenzen</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Monatlich</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Jährlich</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="px-4 py-3">1 - 9</td>
                <td class="px-4 py-3">
                    2 €
                </td>
                <td class="px-4 py-3">
                    7 €
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">10 - 99</td>
                <td class="px-4 py-3">
                    2 €
                </td>
                <td class="px-4 py-3">
                    6 €
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Ab 100</td>
                <td class="px-4 py-3">
                    2 €
                </td>
                <td class="px-4 py-3">
                    5 €
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Ab 1000</td>
                <td class="px-4 py-3">
                    2 €
                </td>
                <td class="px-4 py-3">
                    4 €
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Ab 10000</td>
                <td class="px-4 py-3">
                    2 €
                </td>
                <td class="px-4 py-3">
                    3 €
                </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="container px-5 py-12 mx-auto">
        <div class="flex flex-col text-center">
            <div class="max-w-prose mx-auto mb-12">
                <a class="text-indigo-600 hover:underline cursor-pointer" (click)="pricing = !pricing">
                    Detaillierte Preistabellen
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="pricing">
        <div class="container px-5 pb-12 mx-auto">
            <div class="flex flex-col text-center">
                <div class="max-w-prose mx-auto">
                    <h2 class="title-font text-gray-800 sm:text-3xl text-2xl mb-4 font-medium max-w-md">
                        Preise pro Million Tokens
                    </h2>
                </div>
                <p class="mx-auto max-w-prose">
                    1 Token ≈ 3/4 Wort
                </p>
            </div>
        </div>

        <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top mb-12">
            <table class="table-auto w-prose mx-auto text-left whitespace-no-wrap bg-white dark:bg-gray-900 rounded-md">
                <thead>
                <tr>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Lokale GPU</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Lukaz</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Claude</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Gemini</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">OpenAI</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="px-4 py-3">0,17 - 0,42 €</td>
                    <td class="px-4 py-3 text-lg text-gray-900">
                        2 €
                    </td>
                    <td class="px-4 py-3">
                        4 €
                    </td>
                    <td class="px-4 py-3">
                        5 €
                    </td>
                    <td class="px-4 py-3">
                        10 €
                    </td>
                </tr>

                </tbody>
            </table>
        </div>

        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-col text-center">
                <div class="max-w-prose mx-auto">
                    <h2 class="title-font text-gray-800 sm:text-3xl text-2xl mb-4 font-medium max-w-md">
                        Tokens / Monat pro KI-Anbieter
                    </h2>
                </div>
                <p class="mx-auto max-w-prose">
                    1 Million Tokens ≈ 2500 Seiten
                </p>
            </div>
        </div>

        <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top">
            <table class="table-auto w-prose mx-auto text-left whitespace-no-wrap bg-white dark:bg-gray-900 rounded-md mb-12">
                <thead>
                <tr>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Jährlich</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Lokale GPU</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Lukaz</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Claude</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Gemini</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">OpenAI</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="px-4 py-3">7 €</td>
                    <td class="px-4 py-3">Unbegrenzt</td>
                    <td class="px-4 py-3 text-lg text-gray-900">
                        291667
                    </td>
                    <td class="px-4 py-3">
                        145837
                    </td>
                    <td class="px-4 py-3">
                        116670
                    </td>
                    <td class="px-4 py-3">
                        58333
                    </td>
                </tr>

                <tr>
                    <td class="px-4 py-3">6 €</td>
                    <td class="px-4 py-3">Unbegrenzt</td>
                    <td class="px-4 py-3 text-lg text-gray-900">
                        250000
                    </td>
                    <td class="px-4 py-3">
                        125003
                    </td>
                    <td class="px-4 py-3">
                        100003
                    </td>
                    <td class="px-4 py-3">
                        50000
                    </td>
                </tr>

                <tr>
                    <td class="px-4 py-3">5 €</td>
                    <td class="px-4 py-3">Unbegrenzt</td>
                    <td class="px-4 py-3 text-lg text-gray-900">
                        208333
                    </td>
                    <td class="px-4 py-3">
                        104169
                    </td>
                    <td class="px-4 py-3">
                        83336
                    </td>
                    <td class="px-4 py-3">
                        41667
                    </td>
                </tr>

                <tr>
                    <td class="px-4 py-3">4 €</td>
                    <td class="px-4 py-3">Unbegrenzt</td>
                    <td class="px-4 py-3 text-lg text-gray-900">
                        166667
                    </td>
                    <td class="px-4 py-3">
                        83336
                    </td>
                    <td class="px-4 py-3">
                        66668
                    </td>
                    <td class="px-4 py-3">
                        33333
                    </td>
                </tr>

                <tr>
                    <td class="px-4 py-3">3 €</td>
                    <td class="px-4 py-3">Unbegrenzt</td>
                    <td class="px-4 py-3 text-lg text-gray-900">
                        125000
                    </td>
                    <td class="px-4 py-3">
                        62502
                    </td>
                    <td class="px-4 py-3">
                        50001
                    </td>
                    <td class="px-4 py-3">
                        25000
                    </td>
                </tr>

                </tbody>
            </table>
        </div>

        <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top pb-12 lg:pb-24">
            <table class="table-auto w-prose mx-auto text-left whitespace-no-wrap bg-white dark:bg-gray-900 rounded-md mb-12">
                <thead>
                <tr>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Monatlich</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Lokale GPU</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Lukaz</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Claude</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Gemini</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">OpenAI</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="px-4 py-3">2 €</td>
                    <td class="px-4 py-3">Unbegrenzt</td>
                    <td class="px-4 py-3 text-lg text-gray-900">
                        1000000
                    </td>
                    <td class="px-4 py-3">
                        500013
                    </td>
                    <td class="px-4 py-3">
                        400010
                    </td>
                    <td class="px-4 py-3">
                        200000
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
    </div>

</section>

<!--<tekr-contact-section></tekr-contact-section>-->
<tekr-contact-section-de></tekr-contact-section-de>

<section class="text-gray-600 bg-indigo-500 body-font md:bg-internet-white md:bg-top" id="pricing-second">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <!--<div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>-->
                <div class="w-full">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white dark:text-white text-center md:text-left">
                        Kompatibilität mit<br/>
                        allen Endgeräten
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top pb-12 lg:pb-24">
        <table class="table-auto w-full text-left whitespace-no-wrap bg-white dark:bg-gray-900 rounded-md mb-12">
                <thead>
                    <tr>
                        <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Plattform</th>
                        <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Lukaz</th>
                        <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Fobizz</th>
                        <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">schulKI</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="px-4 py-3">Browser</td>
                        <td class="px-4 py-3">
                            <div>
                            <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                        </td>
                        <td class="px-4 py-3">
                            <div>
                                <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                </svg>
                            </div>
                        </td>
                        <td class="px-4 py-3 text-lg text-gray-900">
                            <div>
                                <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                </svg>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="px-4 py-3">iOS</td>
                        <td class="px-4 py-3">
                            <div>
                            <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                        </td>
                        <td class="px-4 py-3">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                        <td class="px-4 py-3 text-lg text-gray-900">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="px-4 py-3">Android</td>
                        <td class="px-4 py-3">
                            <div>
                            <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                        </td>
                        <td class="px-4 py-3">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                        <td class="px-4 py-3 text-lg text-gray-900">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="px-4 py-3">Windows</td>
                        <td class="px-4 py-3">
                            <div>
                            <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                        </td>
                        <td class="px-4 py-3">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                        <td class="px-4 py-3 text-lg text-gray-900">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="px-4 py-3">MacOS</td>
                        <td class="px-4 py-3">
                            <div>
                            <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                        </td>
                        <td class="px-4 py-3">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                        <td class="px-4 py-3 text-lg text-gray-900">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="px-4 py-3">Linux</td>
                        <td class="px-4 py-3">
                            <div>
                            <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                        </td>
                        <td class="px-4 py-3">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                        <td class="px-4 py-3 text-lg text-gray-900">
                            <div>
                                <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
    </div>
</section>

<section class="relative text-gray-600 bg-gray-100 body-font md:bg-connections-black-inv md:bg-top" id="pricing-third">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-col text-center">
                <div class="max-w-prose mx-auto">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4 max-w-md">
                        Barrierefreiheit<br/>für jeden Nutzer
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top pb-12 lg:pb-24">
        <table class="table-auto w-full text-left whitespace-no-wrap bg-white dark:bg-gray-900 rounded-md mb-12">
            <thead>
            <tr>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Plattform</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Lukaz</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Fobizz</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">schulKI</th>
            </tr>
            </thead>
            <tbody>

            <tr>
                <td class="px-4 py-3">WCAG 2.1 Level AA</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Textvergrößerung</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Mauslose Bedienung</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Sprachsteuerung</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Dunkel/Hell Modi</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Einheitliche Oberfläche</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Mehrsprachige Anwendung</td>
                <td class="px-4 py-3">
                    10 Sprachen inkl. Ukrainisch
                </td>
                <td class="px-4 py-3">
                    Deutsch, Englisch
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            </tbody>
        </table>
    </div>
</section>

<tekr-pipeline-section-de></tekr-pipeline-section-de>

<section class="text-gray-600 bg-indigo-500 body-font md:bg-work-white md:bg-top" id="pricing-fourth">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <!--<div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>-->
                <div class="w-full">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white dark:text-white text-center md:text-left">
                        Integration mit<br/>
                        allen KI-Modellen
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top pb-12 lg:pb-24">
        <table class="table-auto w-full text-left whitespace-no-wrap bg-white dark:bg-gray-900 rounded-md mb-12">
            <thead>
            <tr>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Plattform</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Lukaz</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Fobizz</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">schulKI</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="px-4 py-3">GPT</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Gemini</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Claude</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Llama</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">DeepSeek</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Mistral</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Qwen</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Gemma</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Phi</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">DALL-E</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Stable Diffusion</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Midjourney</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Wolfram Alpha</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Freie Auswahl der KI-Modelle</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Neue KI-Modelle sofort verfügbar</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            </tbody>
        </table>
    </div>
</section>

<section class="relative text-gray-600 bg-gray-100 body-font md:bg-people-black-inv md:bg-top" id="pricing-fifth">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-col text-center">
                <div class="max-w-prose mx-auto">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4 max-w-md">
                        Zukunftssichere<br/>Infrastruktur
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top pb-12 lg:pb-24">
        <table class="table-auto w-full text-left whitespace-no-wrap bg-white dark:bg-gray-900 rounded-md mb-12">
            <thead>
            <tr>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Plattform</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Lukaz</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Fobizz</th>
                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">schulKI</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="px-4 py-3">DSGVO-Konform</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">API Anbindung</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">On-Premise Version</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Desktop Version</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Nutzung ohne Internet</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">Individuell anpassbar</td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="px-4 py-3">ISO-27001 Zertifizierung</td>
                <td class="px-4 py-3">
                    <div>
                        Laufend
                    </div>
                </td>
                <td class="px-4 py-3">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
                <td class="px-4 py-3 text-lg text-gray-900">
                    <div>
                        <svg class="w-3 h-3 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </td>
            </tr>

            </tbody>
        </table>
    </div>
</section>

<!--<tekr-careers-section></tekr-careers-section>-->


<tekr-contact-section-de></tekr-contact-section-de>
