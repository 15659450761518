import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Meta} from '@angular/platform-browser';

@Component({
    selector: 'tekr-use-cases-de',
    templateUrl: './use-cases-de.component.html',
    styleUrls: ['./use-cases-de.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UseCasesDeComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, private metaTagUseCase: Meta,
                private ref: ChangeDetectorRef) {
    }

    ngOnInit() {
        /*this.metaTagUseCase.updateTag(
            {
                name: 'keywords',
                content: 'OOOii',
            },
            'keywords'
        );*/
        this.ref.markForCheck()
        localStorage.setItem('refreshed', 'false')
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }
}
