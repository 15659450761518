<section class="text-gray-100 bg-indigo-500 md:bg-work-white md:bg-center body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font text-gray-100 dark:text-gray-100 sm:text-4xl text-3xl mb-4 font-medium max-w-md">
                AI Solutions for a Modern Classroom
            </h1>
            <p class="mb-8 text-gray-100 dark:text-gray-100 leading-relaxed max-w-prose">
                Transform your teaching experience with tools that create engaging learning materials, personalize student interactions, and make your lectures fully integrative.
            </p>
            <div class="container mx-auto flex flex-col md:flex-row gap-2 md:gap-4">
                <div class="">
                    <button (click)="onClick('teaching-first')"
                            id="teaching"
                            class="mb-4 click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                        Learn more
                    </button>
                </div>
                <div class="">
                    <a href="https://lukaz.ai" target="_blank"
                       id="teaching-cta"
                       class="click-tag inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                        Create account
                    </a>
                </div>
            </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        </div>
    </div>
</section>

<section class="relative text-gray-600 bg-gray-100 body-font md:bg-people-black-inv md:bg-top" id="teaching-first">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full">
                    <h1 class="sm:text-3xl text-gray-900 text-2xl font-medium title-font mb-2 text-gray-100 text-center lg:text-left">
                        Generation of<br/>
                        Interactive Quizzes
                    </h1>
                </div>
            </div>
        </div>
    </div>
    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top">
        <div class="w-full h-full lg:w-6/12 items-center text-center pb-12 md:pr-10">
            <img src="assets/img/screenshots/teaching-1.png" alt="Lukaz Board" class="mx-auto max-h-screen"/>
        </div>
        <div
            class="lg:flex-grow lg:w-6/12 flex flex-col lg:items-start lg:text-left">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Multiple Task Types
                            </h2>
                            <p class="leading-relaxed text-base">
                                Choose between multiple-choice, true/false, or descriptive tasks.
                                Generate multiple versions effortlessly to suit your classroom needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Quick Assessment Generation
                            </h2>
                            <p class="leading-relaxed text-base">
                                Input one or more keywords to automatically generate questions and answers.
                                Review the generated answers; visible only to the teacher.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Sharing Assessments
                            </h2>
                            <p class="leading-relaxed text-base">
                                Create groups and share assessments with just one click.
                                All participants receive automatic email notifications.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Automated Grading
                            </h2>
                            <p class="leading-relaxed text-base">
                                Lukaz evaluates submissions, including free written answers, and instantly provides participants with their grades.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mx-auto px-5 flex flex-col lg:flex-row py-12">
        <!--<div class="w-full lg:w-1/5 2xl:w-2/5 h-full text-right">
        </div>-->
        <div class="w-full lg:w-4/5 2xl:w-3/5 h-full text-right pb-12 lg:pb-24">
            <img src="assets/img/screenshots/teaching-2.png" alt="Lukaz Board" class="mx-auto max-w-screen"/>
        </div>
    </div>
</section>

<!--<tekr-contact-section></tekr-contact-section>-->

<section class="text-gray-600 bg-indigo-500 body-font md:bg-internet-white md:bg-top" id="teaching-second">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <!--<div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>-->
                <div class="w-full">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white dark:text-white text-center md:text-left">
                        Creation of<br/>
                        Lecture Materials
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container mx-auto px-5 flex pb-12">
        <div class="w-full h-full text-center md:text-left">
            <img src="assets/img/screenshots/teaching-3.png" alt="Lukaz Board" class="hidden lg:block max-h-screen"/>
            <img src="assets/img/screenshots/teaching-3-mobile.png" alt="Lukaz Board" class="lg:hidden max-h-screen mx-auto md:mx-0"/>
        </div>
    </div>
    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top pb-12">
        <div
            class="lg:flex-grow lg:w-6/12 flex flex-col lg:items-start lg:text-left pb-12">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Upload Context Files
                            </h2>
                            <p class="leading-relaxed text-base">
                                Seamlessly upload DOCX, PPTX, PDF, CSV, TXT, or MD files. Have scanned documents? Lukaz's OCR feature has you covered.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Text, Image and Audio Generation
                            </h2>
                            <p class="leading-relaxed text-base">
                                Create illustrations, set up handouts in your unique style, and even generate audio descriptions to enrich your lectures.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Template-Based Content Generation
                            </h2>
                            <p class="leading-relaxed text-base">
                                Select a standard template or create your own for consistent and automated content creation.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Content Export
                            </h2>
                            <p class="leading-relaxed text-base">
                                Save your materials as DOC, PDF, TXT, or CSV files, including all references and generated visuals, for further editing in your favorite tools.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full h-full lg:w-6/12 items-center text-center pb-12 md:pb-24 lg:pl-10">
            <img src="assets/img/screenshots/teaching-4.png" alt="Lukaz Board" class="mx-auto max-h-screen"/>
        </div>
    </div>
</section>

<!--
<section class="text-gray-600 bg-indigo-500 body-font md:bg-people-white-inv md:bg-top" id="teaching-second">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-center text-2xl font-medium title-font mb-2 text-gray-100 dark:text-gray-100">
                        Your organization<br/>
                        empowered by AI
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="container px-5 py-5 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">

                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Prompt by voice
                            </h2>
                            <p class="leading-relaxed text-base">
                                Prompt something in italian using a german document as context while
                                requesting the result to be in english. No need of switching languages.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Magic edit
                            </h2>
                            <p class="leading-relaxed text-base">
                                Edit the results of your prompts within a click. Simply enter the editing instructions
                                with your own words and let Lukaz do the rest.
                            </p>
                            &lt;!&ndash;<h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Result re-ordering
                            </h2>
                            <p class="leading-relaxed text-base">
                                Generate larger text documents step-by-step while re-ordering and moving prompt
                                results around (e.g. book pages or paragraphs).
                            </p>&ndash;&gt;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container px-5 py-5 pb-36 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Results export
                            </h2>
                            <p class="leading-relaxed text-base">
                                Forget about manually creating separated documents for copy pasting results.
                                Directly download your prompts' results in DOC, PDF, TXT and CSV.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Image generation
                            </h2>
                            <p class="leading-relaxed text-base">
                                Type some keywords and get incredibly realistic AI-generated pictures out of it. Mix textual with
                                visual content in the same board.
                            </p>
                            &lt;!&ndash;<h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                List and grid views
                            </h2>
                            <p class="leading-relaxed text-base">
                                Use your boards for several unrelated purposes. Get insights and compare content faster while
                                visualizing text in different layouts.
                            </p>&ndash;&gt;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->

<tekr-pipeline-section></tekr-pipeline-section>


<section class="text-gray-600 bg-indigo-500 body-font md:bg-tech-white md:bg-top" id="teaching-third">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <!--<div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>-->
                <div class="w-full">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white dark:text-white">
                        Customization of<br/>
                        Student Interaction
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container mx-auto px-5 flex flex-wrap pb-12 gap-8">
        <div class="h-full">
            <img src="assets/img/screenshots/teaching-5.png" alt="Lukaz Board" class="max-h-screen"/>
        </div>
        <div class="h-full">
            <img src="assets/img/screenshots/teaching-6.png" alt="Lukaz Board" class="max-h-screen"/>
        </div>
    </div>
    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top pb-12">
        <div
            class="lg:flex-grow lg:w-6/12 flex flex-col lg:items-start lg:text-left pb-12">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Flexible Teacher Controls
                            </h2>
                            <p class="leading-relaxed text-base">
                                Edit, hide, or reveal AI-generated content to students as needed.
                                Keep unused materials for future use.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Interactive Board Behavior
                            </h2>
                            <p class="leading-relaxed text-base">
                                Set boards to function as interactive games or step-by-step tutors that guide students rather than simply providing answers.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Manage Context File Access
                            </h2>
                            <p class="leading-relaxed text-base">
                                Upload lesson materials and decide whether students can view them instantly or at a later time.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Control Student Prompting
                            </h2>
                            <p class="leading-relaxed text-base">
                                Disable student prompting while maintaining generated content access,
                                or allow students to create their own study materials.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lg:flex-grow lg:w-6/12 flex flex-col lg:items-start lg:text-left pb-12 pl-10">
            <div class="w-full h-full items-center text-center pb-12 lg:pb-24">
                <img src="assets/img/screenshots/teaching-7.png" alt="Lukaz Board" class="mx-auto max-h-screen"/>
            </div>
        </div>
    </div>
</section>

<!--<section class="text-gray-600 bg-indigo-500 body-font md:bg-tech-white-inv md:bg-top" id="teaching-third">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-center text-2xl font-medium title-font mb-2 text-gray-100 dark:text-gray-100">
                        Be light-years ahead<br/>
                        your competitors
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="container px-5 py-5 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Prompt result editor
                            </h2>
                            <p class="leading-relaxed text-base">
                                Edit the text of the prompt results directly on your AI board.
                                Seamlessly compare, transform, revert or delete generations.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Quantity to generate
                            </h2>
                            <p class="leading-relaxed text-base">
                                Choose how many results to generate for prompts such as social media posts,
                                school exercises, pages of books or even research papers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container px-5 py-5 pb-36 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Results audio
                            </h2>
                            <p class="leading-relaxed text-base">
                                Listen to your prompts' results, no matter how long and in which language they are.
                                Preview and choose different voices for each board.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Editing templates</h2>
                            <p class="leading-relaxed text-base">
                                Automate your prompting workflow by seamlessly creating editing templates that can be
                                applied to any prompt result with a click.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<!--<tekr-careers-section></tekr-careers-section>-->

<section class="relative text-gray-600 bg-gray-100 body-font md:bg-connections-black-inv md:bg-top" id="teaching-fourth">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full">
                    <h1 class="sm:text-3xl text-gray-900 text-2xl font-medium title-font mb-2 text-gray-100 text-center lg:text-left">
                        Fully Integrative<br/>
                        AI Tool for Any Student
                    </h1>
                </div>
            </div>
        </div>
    </div>
    <div class="container mx-auto flex px-5 lg:flex-row flex-col align-top">
        <div class="w-full h-full lg:w-6/12 items-center text-center pb-12 md:pr-10">
            <img src="assets/img/screenshots/teaching-8.png" alt="Lukaz Board" class="mx-auto max-h-screen"/>
        </div>
        <div
            class="lg:flex-grow lg:w-6/12 flex flex-col lg:items-start lg:text-left">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Multilingual Interface and Generation
                            </h2>
                            <p class="leading-relaxed text-base">
                                A user interface available in English, Spanish, Portuguese, French, German, Turkish, Italian, Polish, Ukrainian, and Dutch — with generation in over 30 languages.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Voice-Prompting and Mouseless Interaction
                            </h2>
                            <p class="leading-relaxed text-base">
                                Operate Lukaz entirely with your voice or keyboard. Speak your prompts and listen to
                                audio outputs for a seamless experience.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Dark/Light Mode and Adjustable Text Sizes
                            </h2>
                            <p class="leading-relaxed text-base">
                                Switch between light and dark themes on any screen and customize text size to meet individual student needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Cross-Device Compatibility
                            </h2>
                            <p class="leading-relaxed text-base">
                                Access Lukaz from any computer, tablet, or phone via web browser, iOS, or Android apps — or install it on your school’s server for centralized access.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mx-auto flex px-5 py-12">
        <div class="w-full h-full items-center text-center pb-12 lg:pb-24">
            <img src="assets/img/screenshots/teaching-9.png" alt="Lukaz Board" class="mx-auto max-h-screen"/>
        </div>
    </div>
</section>

<tekr-contact-section></tekr-contact-section>
<!--<section class="relative text-gray-600 bg-gray-100 md:bg-connections-black-inv md:bg-top body-font" id="teaching-fourth">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-gray-900 text-center text-2xl font-medium title-font mb-2 text-gray-100">
                        Most flexible<br/>
                        AI editor to date
                    </h1>
                    &lt;!&ndash;                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>&ndash;&gt;
                    &lt;!&ndash;<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>&ndash;&gt;
                </div>
            </div>
        </div>
    </div>
    <div class="container px-5 py-5 pb-36 mx-auto flex flex-wrap">
        <div class="flex flex-wrap -m-4">
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Chat and prompt modes
                        </h2>
                        <p class="leading-relaxed text-base">
                            Enhance the prompt engineering skills of your users while letting them interact
                            with a board in two different prompting perspectives.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            HTML preview
                        </h2>
                        <p class="leading-relaxed text-base">
                            Visualize your generated pure HTML code or even HTML + TailwindCSS. Further transform
                            UX elements with developer templates.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Syntax highlighting
                        </h2>
                        <p class="leading-relaxed text-base">
                            Generate and edit code in different programming languages in the same board.
                            Transform or regenerate your code with one click.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Free API access
                        </h2>
                        <p class="leading-relaxed text-base">
                            Build state-of-the-art AI software or integrate any existing system with our robust API.
                            Get support from our community.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
