import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ViewportScroller} from "@angular/common";

@Component({
    selector: 'tekr-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {

    constructor(private router: Router, private viewportScroller: ViewportScroller) {
    }

    ngOnInit(): void {
        localStorage.setItem('refreshed', 'false')

        if (localStorage.getItem('lang') === 'de') {
            this.router.navigate(['de/imprint'])
        }
    }

}
