import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'tekr-header-de',
    templateUrl: './header-de.component.html',
    styleUrls: ['./header-de.component.css']
})
export class HeaderDeComponent implements OnInit {
    darkMode = false;

    constructor() {
    }

    ngOnInit(): void {
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark')
            this.darkMode = true
        } else {
            document.documentElement.classList.remove('dark')
            this.darkMode = false
        }
    }

    toggleDarkMode(): void {
        if (localStorage.theme === 'dark') {
            localStorage.theme = 'light'
            document.documentElement.classList.remove('dark')
            this.darkMode = false
        } else {
            localStorage.theme = 'dark'
            document.documentElement.classList.add('dark')
            this.darkMode = true
        }
    }

}
