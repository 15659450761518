<section class="text-gray-600 bg-white dark:bg-gray-900 md:bg-connections-purple md:bg-top body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-lg">
                Eine anpassbare KI-gesteuerte Umgebung für jede Person
            </h1>
            <p class="mb-8 leading-relaxed max-w-prose">
                Vom Erzeugen von Social-Media-Posts oder Schulaufgaben bis hin zum topaktuellen Kundensupport, Lukaz
                ist die richtige Wahl, um von den neuesten KI-Innovationen zu profitieren.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <button (click)="onClick('use-cases')"
                        id="ai-lm"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                    Mehr erfahren
                </button>
                <a href="https://lukaz.ai" target="_blank"
                   id="ai-cta"
                   class="click-tag ml-2 md:ml-4 inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 rounded text-lg">
                    KI-Board erstellen
                </a>
            </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        </div>
    </div>
</section>

<section class="text-gray-600 bg-indigo-500 body-font md:bg-tech-white-inv md:bg-top" id="use-cases">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-center text-2xl font-medium title-font mb-2 text-gray-100 dark:text-gray-100">
                        Unser optimales<br/>
                        All-in-One KI-System
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="container px-5 py-5 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                KI-gesteuerte Bildung und E-Learning
                            </h2>
                            <p class="leading-relaxed text-base">
                                Erstellen Sie interaktive Lernboards für jedes Fach. Laden Sie Materialien hoch,
                                die in Aufgaben wie der Erstellung von Übungen als Kontext verwendet werden können.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Kundensupport & Interaktive FAQs
                            </h2>
                            <p class="leading-relaxed text-base">
                                Ermöglichen Sie Ihren Kunden, Fragen zu stellen oder Prompts mit Sprachbefehlen in
                                mehreren Sprachen zu senden, ohne die Sprache auswählen zu müssen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container px-5 py-5 pb-36 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Produktentwicklung und Innovation
                            </h2>
                            <p class="leading-relaxed text-base">
                                Treffen Sie fundierte Entscheidungen über Produktfunktionen und Verbesserungen, indem
                                Sie das Kundenfeedback automatisieren.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                                Instant Marketing und Werbung
                            </h2>
                            <p class="leading-relaxed text-base">
                                Erzeugen Sie sekundenschnelle Werbekampagnen und verbessern Sie die Wirksamkeit, indem
                                Sie Ihr vorhandenes Textmaterial als Kontext verwenden.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<tekr-contact-section-de></tekr-contact-section-de>

<tekr-pipeline-section-de></tekr-pipeline-section-de>

<section class="relative text-gray-600 bg-gray-100 md:bg-connections-black-inv md:bg-top body-font" id="features-fourth">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-gray-900 text-center text-2xl font-medium title-font mb-2 text-gray-100">
                        Ihre Organisation,<br/>
                        ermächtigt durch KI
                    </h1>
                    <!--                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>-->
                    <!--<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>-->
                </div>
            </div>
        </div>
    </div>
    <div class="container px-5 py-5 pb-36 mx-auto flex flex-wrap">
        <div class="flex flex-wrap -m-4">
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">

                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Inhaltliche Kuratierung und Nachrichtenaggregation
                        </h2>
                        <p class="leading-relaxed text-base">
                            Laden Sie frühere Arbeiten hoch und erstellen Sie Inhalte in Ihrem eigenen Schreibstil.
                            Aggregieren Sie Nachrichten- oder Blogartikel und viele andere Arten von Inhalten.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Forschung & Wissensmanagement
                        </h2>
                        <p class="leading-relaxed text-base">
                            Stellen Sie Fragen an Ihren Quellen und verfassen Sie Forschungsarbeiten,
                            Aufsätze oder jegliche Art von Dokumenten viel effizienter und ohne ungenaue Fakten.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Softwareentwicklung und Dokumentation
                        </h2>
                        <p class="leading-relaxed text-base">
                            Entwickeln Sie viel schneller mit benutzerdefinierten Entwicklervorlagen, Syntaxhervorhebung
                            und sogar Vorschau Ihres generierten Codes in HTML + TailwindCSS.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Medizinische Forschung und klinische Studien
                        </h2>
                        <p class="leading-relaxed text-base">
                            Identifizieren Sie schnell mögliche Inkonsistenzen in medizinischen Berichten und erhalten
                            Sie wertvolle Vorschläge für die potenziellen Behandlungsoptionen.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<tekr-careers-section-de></tekr-careers-section-de>
