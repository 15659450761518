<section class="relative text-gray-600 bg-white dark:bg-gray-900 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-white dark:text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
            <!--            <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">FOR ANY KIND OF SOFTWARE</h2>-->
            <div class="max-w-prose mx-auto">
                <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4 max-w-prose">
                    Dokumente in jedem Format erstellen lassen, 100% DSGVO-konform
                </h1>
            </div>
            <p class="mx-auto max-w-prose">
                Sie fragen sich, wie Sie ein komplettes Buch oder Übungsblatt mithilfe von KI erstellen können? Lukaz ermöglicht
                es Ihnen, nach und nach Inhalte mit Ihrem eigenen Kontext und Ihrer eigenen Anleitung zu erstellen.
                Bearbeiten Sie die Ergebnisse nach Wunsch und erhalten Sie ein zum Download bereites PDF!
            </p>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div
                class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
            </div>
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Kontextdateien hochladen</h2>
                <p class="leading-relaxed text-base">
                    Hören Sie auf, nach zufälligen Kontextteilen in Dutzenden von Dateien zu suchen, nur um sie in
                    Ihren Prompt einzufügen. Mit Lukaz können Sie einfach Ihre vertrauenswürdigen Quellen hochladen,
                    sogar gescannte Dokumente! Personenbezogene Daten werden vor der Verarbeitung anonymisiert.
                </p>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Vorlagen erstellen</h2>
                <p class="leading-relaxed text-base">
                    Wiederholen Sie nie wieder einen Prompt! Mit Vorlagen können Sie Beispiele für gewünschte Ergebnisse
                    speichern, um ein bestimmtes Format zu erreichen oder einfach wiederholende Generierungsaufgaben zu automatisieren.
                </p>
            </div>
            <div
                class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div
                class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </div>
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Prompten und gleich bearbeiten</h2>
                <p class="leading-relaxed text-base">
                    Nach der Auswahl einer Vorlage geben Sie die Eingabe Ihres Prompts ein und wählen die Menge, die Sie
                    generieren möchten (z.B. 4 Seiten eines Buches). Dann bearbeiten oder generieren Sie nach Bedarf
                    einfach die Ergebnisse Ihres Prompts neu!
                </p>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Ergebnis herunterladen</h2>
                <p class="leading-relaxed text-base">
                    Es spielt keine Rolle, ob Sie kurze Beiträge für soziale Medien oder eine komplette Forschungsarbeit
                    erstellen, wählen Sie einfach das Format (PDF, DOC, TXT, CSV) aus und laden Sie Ihre frische Arbeit sofort herunter.
                </p>
            </div>
            <div
                class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                </svg>
            </div>
        </div>
        <button
            (click)="goToLink('https://lukaz.ai')"
            id="pipeline-section-cta"
            class="click-tag flex flex-auto mx-auto text-center mt-15 mb-10 text-gray-100 dark:text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
            Inhalt generieren
        </button>
    </div>
</section>
