import {Component, OnInit} from '@angular/core';
import {ContentService} from '../content.service';

@Component({
    selector: 'tekr-contact-section-de',
    templateUrl: './contact-section-de.component.html',
    styleUrls: ['./contact-section-de.component.css']
})
export class ContactSectionDeComponent implements OnInit {

    content$ = this.contentService.getContent('ctas/1')

    constructor(private contentService: ContentService) {
    }

    ngOnInit(): void {
    }

}
