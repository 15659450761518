<header class="text-gray-600 bg-white dark:bg-gray-900 body-font sticky top-0 z-50 shadow-2xl">
    <div class="container mx-auto flex flex-wrap px-2 flex-row items-center">
        <a id="header-logo" class="click-tag flex items-center mb-1 md:mx-3 mr-2" routerLink="/">
            <img *ngIf="!darkMode" src="assets/img/logo-text.png" style="width: 92px;height: auto;"/>
            <img *ngIf="darkMode" src="assets/img/logo-text-on-dark.png" style="width: 92px;height: auto;"/>
        </a>
        <nav
            class="mr-auto md:ml-4 flex flex-wrap items-center text-base justify-center">

            <ul class="flex pt-2 -mt-2">
                <!--<li class="hoverable hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a [routerLink]="'features'"
                       id="header-features"
                       class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2">
                        Features
                    </a>
                    <div class="p-0 pt-2 sm:p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-indigo-500">
                        <div class="container mx-auto w-full flex flex-wrap justify-between">
                            &lt;!&ndash;<div class="w-full text-gray-100 dark:text-gray-100 mb-8 text-center">
                                <h2 class="font-bold text-2xl">Listen, why don’t you wait out by the speeder.</h2>
                                <p>our droids. They’ll have to wait outside. We don’t want them here.</p>
                            </div>&ndash;&gt;
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'features'"
                                       id="header-upload"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Context File Upload and Results Export
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Upload context files (DOC, PDF, MD, PAGES) with OCR support.
                                    Download your prompts' results in DOC, PDF, TXT and CSV.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-upload-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Upload something
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"/>
                                        </svg>
                                    </div>
                                    <a [routerLink]="'features'"
                                       id="header-instructions"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Custom Templates & Generation Quantity
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Choose how many results to generate and create custom templates for content generation in specific formats.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-instructions-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Automate prompting
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'features'"
                                       id="header-sharable"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Sharable and&lt;!&ndash; <br/> &ndash;&gt;
                                            Customizable AI Boards
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Set up a different AI behavior/persona for each board. Share your AI-driven boards with users of different roles.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-blockchain-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Create AI board
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'features'"
                                       class="click-tag"
                                       id="header-editor">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Result Editor &&lt;!&ndash; <br/> &ndash;&gt;
                                            Editing Templates
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Edit the text of the prompt results directly on your AI board and seamlessly use prompt results as input for other
                                    prompts.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-editor-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Edit text with AI
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">

                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'features'"
                                       id="header-views"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Image Generation & Magic Editing
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Tell Lukaz with your own words what and how to edit your text.
                                    Get incredibly realistic AI-generated pictures.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-views-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Generate image
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'features'"
                                       id="header-preview"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Chat Mode and&lt;!&ndash; <br/> &ndash;&gt;
                                            Prompting Mode
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Enhance the prompt engineering skills of your users while they interact with
                                    a board in two different prompting perspectives.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-preview-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Chat or prompt
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'features'"
                                       id="header-voice"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Prompt by Voice &&lt;!&ndash; <br/> &ndash;&gt;
                                            Result Audio
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Just prompt something in italian using a german document as context
                                    while getting the result in english if you request so.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-voice-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Talk to Lukaz
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'features'"
                                       class="click-tag"
                                       id="header-api">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            HTML Preview and&lt;!&ndash; <br/> &ndash;&gt;
                                            Free API Access
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Build state-of-the-art AI software
                                    or integrate any system with our robust API. Visualize HTML code on your board.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://docs.lukaz.ai" target="_blank"
                                       id="header-api-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        API documentation
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                        </div>
                    </div>
                </li>-->

                <li class="hoverable hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a [routerLink]="'teaching'"
                       id="header-use-cases"
                       class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2">
                        Use Cases
                    </a>
                    <div class="p-0 pt-2 sm:p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-indigo-500">
                        <div class="container mx-auto w-full flex flex-wrap justify-between">

                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'teaching'"
                                       id="header-education"
                                       class="click-tag">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Interactive Assessments
                                        </h3>
                                    </a>
                                </div>
                                <p class="text-gray-100 dark:text-gray-100 text-sm">
                                    Generate and grade dynamic tasks such as multiple-choice, true/false, or descriptive tasks and turn homework into fun.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-education-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Generate assessment
                                    </a>
                                </div>
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'teaching'"
                                       class="click-tag"
                                       id="header-support">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Engaging Materials
                                        </h3>
                                    </a>
                                </div>
                                <p class="text-gray-100 dark:text-gray-100 text-sm">
                                    Create illustrations, set up handouts in your unique style, and even generate
                                    audio descriptions to enrich your lectures.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-support-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Write handout
                                    </a>
                                </div>
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                             stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'teaching'"
                                       id="header-product"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Gamification & Customization
                                        </h3>
                                    </a>
                                </div>
                                <p class="text-gray-100 dark:text-gray-100 text-sm">
                                    Set boards to function as interactive games or step-by-step tutors that guide
                                    students rather than simply providing answers.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-product-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Create AI board
                                    </a>
                                </div>
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">

                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'teaching'"
                                       id="header-marketing"
                                       class="click-tag">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Accessibility & Integration
                                        </h3>
                                    </a>
                                </div>
                                <p class="text-gray-100 dark:text-gray-100 text-sm">
                                    Access Lukaz from any computer, tablet, or phone via web browser, iOS, or Android
                                    apps — or install it on your school’s server.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-marketing-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Register now
                                    </a>
                                </div>
                            </ul>
<!--                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'teaching'"
                                       class="click-tag"
                                       id="header-content">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Content Curation and&lt;!&ndash; <br/> &ndash;&gt;
                                            News Aggregation
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Upload previous work and generate content in your own writing style.
                                    Aggregate news articles, blog posts, and other content.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-content-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Generate blog post
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                             stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'teaching'"
                                       id="header-research"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Research & Knowledge Management
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Ask questions to your sources and write research papers, articles or any kind
                                    of document much more efficiently.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-research-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Upload paper
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                                        </svg>
                                    </div>
                                    <a [routerLink]="'teaching'"
                                       class="click-tag"
                                       id="header-dev">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Software Development and Documentation
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Develop much faster with custom instructions and even preview your generated
                                    HTML + TailwindCSS code.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-dev-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Generate code
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'teaching'"
                                       class="click-tag"
                                       id="header-medical">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Medical Research and&lt;!&ndash; <br/> &ndash;&gt;
                                            Clinical Trials
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Quickly identify possible inconsistencies on medical reports and get suggestions
                                    for potential treatment options.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-medical-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Analyse report
                                    </a>
                                </div>&ndash;&gt;
                            </ul>-->
                        </div>
                    </div>
                </li>
                <li class="hidden sm:block hoverable hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a [routerLink]="'teaching'"
                       id="header-solutions"
                       class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2">
                        Solutions
                    </a>
                    <div class="p-0 pt-2 sm:p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-indigo-500">
                        <div class="container mx-auto w-full flex flex-wrap justify-between">
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'contact'"
                                       id="header-on-premise"
                                       class="click-tag">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Lukaz Server
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Install Lukaz on your existing server infrastructure.
                                    Data processed by LLMs remains entirely within your school's servers.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="contact"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Contact us
                                    </a>
                                </div>
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'contact'"
                                       class="click-tag"
                                       id="header-desktop">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Lukaz Client
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Run Lukaz on any desktop or laptop (Windows, Linux, or MacOS).
                                    Access all local LLMs, even without an internet connection.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="contact"
                                       id="header-client-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Get in touch
                                    </a>
                                </div>
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                             stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/contact'"
                                       id="header-hybrid"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Lukaz API
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Need a customized user interface? Integrate all Lukaz features effortlessly into any system through our robust HTTP API.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="contact"
                                       id="header-custom-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Let's talk
                                    </a>
                                </div>
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">

                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'teaching'"
                                       id="header-cloud"
                                       class="click-tag">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Lukaz Cloud
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    If your school lacks a server, Lukaz Cloud ensures data security with
                                    Hetzner Online servers, secured by Securepoint GmbH.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-cloud-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Create account
                                    </a>
                                </div>
                            </ul>
<!--                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'teaching'"
                                       class="click-tag"
                                       id="header-content">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Content Curation and&lt;!&ndash; <br/> &ndash;&gt;
                                            News Aggregation
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Upload previous work and generate content in your own writing style.
                                    Aggregate news articles, blog posts, and other content.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-content-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Generate blog post
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                             stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'teaching'"
                                       id="header-research"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Research & Knowledge Management
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Ask questions to your sources and write research papers, articles or any kind
                                    of document much more efficiently.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-research-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Upload paper
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                                        </svg>
                                    </div>
                                    <a [routerLink]="'teaching'"
                                       class="click-tag"
                                       id="header-dev">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Software Development and Documentation
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Develop much faster with custom instructions and even preview your generated
                                    HTML + TailwindCSS code.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-dev-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Generate code
                                    </a>
                                </div>&ndash;&gt;
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'teaching'"
                                       class="click-tag"
                                       id="header-medical">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Medical Research and&lt;!&ndash; <br/> &ndash;&gt;
                                            Clinical Trials
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Quickly identify possible inconsistencies on medical reports and get suggestions
                                    for potential treatment options.
                                </p>
                                &lt;!&ndash;<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-medical-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Analyse report
                                    </a>
                                </div>&ndash;&gt;
                            </ul>-->
                        </div>
                    </div>
                </li>
                <!--end Hoverable Link-->

                <!--end Hoverable Link-->
                <li class="hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a routerLink="pricing" class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2"
                       id="header-pricing">
                        Pricing
                    </a>
                </li>
                <!--Regular Link-->
                <!--<li class="hidden sm:block hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a routerLink="developers" class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2"
                       id="header-developers">
                        Developers
                    </a>
                </li>-->
            </ul>
        </nav>
        <button id="switchTheme"
                class="click-tag h-10 w-10 m-6 fixed bottom-12 right-1 justify-center items-center focus:outline-none text-gray-500"
                (click)="toggleDarkMode()" title="Switch dark/light mode">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"/>
            </svg>
        </button>
        <div class="flex flex-row items-center">
            <a href="https://lukaz.ai/sign-up" target="_blank"
            id="header-sign-up"
            class="click-tag md:inline-flex hidden mr-2 items-center bg-indigo-500 text-gray-100 dark:text-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-base mt-4 md:mt-0">
            Sign-up
            </a>

            <a href="https://lukaz.ai/sign-in" target="_blank"
            id="header-log-in"
            class="click-tag md:inline-flex hidden items-center bg-gray-700 text-gray-100 dark:text-indigo-500 border-0 py-1 px-3 focus:outline-none hover:bg-gray-800 rounded text-base mt-4 md:mt-0">
                Log in
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
            </a>
        </div>
    </div>
    <!--
        <div class="bg-gray-200 font-sans leading-normal tracking-normal">

            <nav class="relative bg-white border-b-2 border-gray-300 text-gray-900">
                <div class="container mx-auto flex justify-between">
                    <div class="relative block p-4 lg:p-6 text-base text-indigo-200 font-bold">
                        <a href="#" class="hover:text-indigo-200 dark:hover:text-indigo-200">Logo</a>
                    </div>
                </div>
            </nav>
        </div>-->
</header>
