<section class="text-gray-600 bg-white dark:bg-gray-900 md:bg-work-purple md:bg-top body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-6/12 lg:w-4/12 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <!--<h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-2xl">
                We improve software productivity through artificial intelligence
            </h1>-->
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-md">
                Das KI-Tool zum Lernen und Arbeiten
            </h1>
            <p class="mb-8 leading-relaxed max-w-prose">
                Unsere KI-getriebene Plattform ermöglicht Endbenutzern und Organisationen die Generierung und
                Interaktion mit Text- und Bildinhalten für eine Vielzahl von Aufgaben, auf eine intuitive und
                nützliche Weise.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <a [routerLink]="'features'"
                   id="home-lm-org"
                   class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                    Mehr erfahren
                </a>
                <a href="https://lukaz.ai" target="_blank"
                   id="home-cta-org"
                   class="ml-2 md:ml-4 inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 rounded text-lg">
                    KI-Board erstellen
                </a>
            </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-8/12 items-center text-center">
            <img src="assets/img/screenshots/lukaz-board-1.png" alt="Lukaz Board" class="hidden lg:block mx-auto"/>
            <img src="assets/img/screenshots/lukaz-mobile.png" alt="Lukaz Board" class="lg:hidden mx-auto"/>
        </div>
    </div>

</section>

<section class="relative text-gray-100 bg-indigo-500 body-font md:bg-connections-white-inv md:bg-top">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-indigo-500 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">

        </div>
        <div
            class="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="text-gray-100 dark:text-gray-100 title-font sm:text-4xl text-3xl mb-4 font-medium max-w-md">
                Lukaz fördert die Karriere vieler Fachleute
            </h1>
            <p class="text-gray-100 dark:text-gray-100 mb-8 leading-relaxed max-w-prose">
                Wir helfen Menschen aus vielen Bereichen, ihre Leistung bei der Arbeit zu erhöhen, während wir ihnen
                eine sichere KI-gesteuerte Umgebung anbieten, die auf ihren eigenen vertrauenswürdigen Quellen basiert.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <a [routerLink]="'use-cases'"
                   id="home-lm-dev"
                   class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                    Mehr erfahren
                </a>
                <a href="https://lukaz.ai" target="_blank"
                   id="home-cta-dev"
                   class="ml-2 md:ml-4 inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                    Account erstellen
                </a>
            </div>
        </div>
    </div>

</section>

<tekr-contact-section-de></tekr-contact-section-de>

<section class="relative text-gray-600 bg-gray-100 md:bg-people-white-inv md:bg-top body-font" id="reminder">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto text-center">
        <div class="flex flex-col text-center w-full mb-10">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Einige unserer Partner</h1>
        </div>

        <div class="lg:w-1/2 md:w-2/3 mx-auto text-center w-full">
            <div class="mb-10">
                <h2 class="lg:w-2/3 mx-auto leading-relaxed max-w-prose mb-4">
                    Wortmann AG
                </h2>
                <img src="assets/img/partners/terra.png" alt="Terra" class="mx-auto rounded-xl shadow-xl"/>
            </div>
            <div class="mb-10">
                <h2 class="lg:w-2/3 mx-auto leading-relaxed max-w-prose mb-4">
                    Microsoft Corporation
                </h2>
                <img src="assets/img/partners/microsoft.png" alt="Microsoft" class="mx-auto rounded-xl shadow-xl"/>
            </div>
            <!--<div class="mb-10">
                <h2 class="lg:w-2/3 mx-auto leading-relaxed max-w-prose mb-4">
                    Canify AG
                </h2>
                <img src="assets/img/partners/canify.png" alt="Canify" class="mx-auto rounded-xl shadow-xl"/>
            </div>
            <div class="mb-10">
                <h2 class="lg:w-2/3 mx-auto leading-relaxed max-w-prose mb-4">
                    FrontNow GmbH
                </h2>
                <img src="assets/img/partners/frontnow.png" alt="FrontNow" class="mx-auto rounded-xl shadow-xl"/>
            </div>-->
<!--            <div class="mb-10">
                <h2 class="lg:w-2/3 mx-auto leading-relaxed max-w-prose mb-4">
                    MyDose LLC
                </h2>
                <img src="assets/img/partners/mydose.png" alt="MyDose" class="mx-auto rounded-xl shadow-xl"/>
            </div>-->
            <!--<div class="mb-10">
                <h2 class="lg:w-2/3 mx-auto leading-relaxed max-w-prose mb-4">
                    C-Neutral SAS
                </h2>
                <img src="assets/img/partners/c-neutral.png" alt="C-Neutral" class="mx-auto rounded-xl shadow-xl"/>
            </div>
            <div class="mb-10">
                <h2 class="lg:w-2/3 mx-auto leading-relaxed max-w-prose mb-4">
                    Pure Flavour GmbH
                </h2>
                <img src="assets/img/partners/pure-flavour.png" alt="Pure Flavour" class="mx-auto rounded-xl shadow-xl"/>
            </div>
            <div class="mb-10">
                <h2 class="lg:w-2/3 mx-auto leading-relaxed max-w-prose mb-4">
                    Customized Drinks GmbH
                </h2>
                <img src="assets/img/partners/braufaesschen.png" alt="Braufässchen" class="mx-auto rounded-xl shadow-xl"/>
            </div>-->
        </div>

    </div>
</section>


<tekr-pipeline-section-de></tekr-pipeline-section-de>
