import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tekr-imprint-de',
  templateUrl: './imprint-de.component.html',
  styleUrls: ['./imprint-de.component.css']
})
export class ImprintDeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      localStorage.setItem('refreshed', 'false')
  }

}
