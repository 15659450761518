import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
// import {OdooConnector} from '../../../ng-odoo-connect/odoo-connector.service';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'tekr-contact-de',
    templateUrl: './contact-de.component.html',
    styleUrls: ['./contact-de.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDeComponent implements OnInit {
    // odoo = new OdooConnector(
    //     'https://backoffice.tekr.io',
    //     // 'https://stage.backoffice.tekr.io',
    //     // 'http://backoffice.local',
    //     'tekr-erp',
    //     'admin@tekr.io',
    //     'e2762fa8e08d3b58ee9622d93cef8f958a295420',
    //     // '95b9e3f1c197915e7763ab8e448d49ffcdc714c8',
    //     // '2897720084af565e7f765b81f89fede9d4dad20d'
    // );

    contactForm: FormGroup;

    hookUrl = 'https://hooks.slack.com/services/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';
    formUrl = 'https://z1ll1x.api.infobip.com/forms/1/forms/9db9a517-ec56-4e14-adea-223315b7251e/data';

    constructor(private viewportScroller: ViewportScroller, private ref: ChangeDetectorRef,
                private httpClient: HttpClient) {
    }

    ngOnInit(): void {
        this.contactForm = new FormGroup({
            firstName: new FormControl(),
            lastName: new FormControl(),
            email: new FormControl(),
            phone: new FormControl(),
            message: new FormControl(),
            // budget: new FormControl(),
            agreement: new FormControl(),
        });
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }

    sendContactForm(): void {
        const formData = this.contactForm.getRawValue();
        if (!formData.firstName) return alert('Bitte geben Sie Ihren Vornamen an.');
        if (!formData.lastName) return alert('Bitte geben Sie Ihren Nachnamen an.');
        if (!formData.email) return alert('Bitte geben Sie eine gültige E-Mail-Adresse an.');
        if (!formData.message) return alert('Bitte geben Sie Ihre Nachricht ein.');
        if (!formData.agreement) return alert('Bitte stimmen Sie zu, dass Sie unsere Datenschutzerklärung gelesen haben.');

        this.contactForm.disable();
        const tags = [];
        tags.push(22);
        // if (formData.consulting) {
        //     tags.push(23);
        // }
        // if (formData.srs) {
        //     tags.push(24);
        // }
        // if (formData.funding) {
        //     tags.push(1);
        // }
        // if (formData.erp) {
        //     tags.push(2);
        // }
        // if (formData.saas) {
        //     tags.push(3);
        // }
        // if (formData.ai) {
            tags.push(4);
        // }
        // if (formData.blockchain) {
        //     tags.push(5);
        // }
        // if (formData.others) {
        //     tags.push(6);
        // }
        // if (formData.allocation) {
        //     tags.push(10);
        // }
        // if (formData.budget) {
        //     tags.push(parseInt(formData.budget));
        // }
        /*const contactData = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            website: formData.company,
            comment: formData.message,
            category_id: tags,
            // team_id: 1
        };*/
        delete formData.agreement
        this.createContact(formData);
    }

    // tslint:disable-next-line:typedef
    createContact(values) {
        // [Demo] Get Odoo server Data
        // this.odoo.data().subscribe((res: any) => res);
        document.getElementById('contactForm').innerHTML = '' +
            '<div class="flex flex-col text-center mx-auto mb-10">' +
            '<p class="font-bold">Thank you for your message!</p>' +
            '</div>';
        this.onClick('contact');
        /*let data = {
            'text': 'New lead from website: \n' +
                values.name + '\n' +
                values.email + '\n' +
                values.phone + '\n' +
                values.website + '\n' +
                values.comment + '\n'
        };*/
        /*let data = {
            name: values.name,
            company:  values.website,
            email: values.email,
            phone: values.phone,
            message:  values.comment
        };*/
        // @ts-ignore
        // data = JSON.stringify(data);
        this.httpClient.post(this.formUrl, values, { headers: { 'Authorization': 'App 60ac17b0e8c7233ba5cf47368d1fcf31-157caa98-731a-4d9f-8e74-b8b67f91e3fa'}})
            .subscribe(d => {
                // console.log(d);
            });
// [Demo] LogIn & SearchRead res.partner
        /*this.odoo.login().subscribe((res: any) => {
            this.odoo.create(
                'res.partner',
                values
                // [['customer', '=', true]],
                // {fields: ['name'], limit: 5}
            ).subscribe((id: any) => {
                // console.log(obj);
                document.getElementById('contactForm').innerHTML = '' +
                    '<div class="flex flex-col text-center mx-auto mb-10">' +
                    '<p class="font-bold">Thank you for your message!</p>' +
                    '</div>';
                this.onClick('contact');
                let data = {
                    'text': 'New lead from website: \n' +
                        values.name + '\n' +
                        values.email + '\n' +
                        values.phone + '\n' +
                        values.website + '\n' +
                        values.comment + '\n' +
                        'https://backoffice.tekr.io/web#id=' + id + '&action=454&cids=2&menu_id=299&model=res.partner&view_type=form',
                };
                // @ts-ignore
                data = JSON.stringify(data);
                this.httpClient.post(this.hookUrl, data)
                    .subscribe(d => {
                        console.log(d);
                    });
            });
        });*/
    }
}
