<section class="text-gray-600 bg-gray-100 md:bg-tech-black md:bg-center body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="max-w-lg title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                Möchten Sie Lukaz bei Ihrer Organisation umsetzen?
            </h1>
            <p class="mb-8 leading-relaxed max-w-prose">
                Sie können uns per Chat,
                <a class="click-tag text-indigo-700 hover:underline" href="tel:+498954194791" id="contact-phone">Telefon</a>,
                <!--                <a class="text-indigo-700" href="tel:+3727120779">phone</a>,-->
                <!--                <a class="click-tag text-indigo-700 hover:underline"
                                   id="contact-whatsapp"
                                   href="https://wa.me/3727120779?text=Hello,%20I%27m%20interested%20in%20your%20services"
                                   target="_blank">Whatsapp</a>,-->
                <a class="click-tag text-indigo-700 hover:underline" id="contact-email"
                   href="mailto:hallo@lukaz.ai" target="_blank">E-Mail</a>
                oder über das untenstehende Formular eine
                <a class="click-tag text-indigo-700 hover:underline" id="contact-message"
                   href="contact/#contact">Nachricht</a> senden. Lassen Sie Ihr Unternehmen oder Ihre Bildungseinrichtung
                mit innovativer KI-Software modernisieren.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <button (click)="onClick('contact')"
                        id="contact-cta"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
                    Kontaktieren Sie uns
                </button>
            </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">

        </div>
    </div>
</section>

<section class="relative text-gray-600 bg-white dark:bg-gray-900 body-font" id="contact">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-white dark:text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Senden Sie uns eine Nachricht</h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base max-w-prose">
                Unser Team wird Ihre Anfrage innerhalb eines Arbeitstages beantworten.
            </p>
        </div>

        <div class="lg:w-1/2 md:w-2/3 mx-auto">
            <form [formGroup]="contactForm" class="flex flex-wrap -m-2" id="contactForm">

                <!--                <h2 class="sm:text-xl p-2 w-full text-lg font-medium title-font mb-4 text-gray-900">Tell us more about
                                    you</h2>-->

                <div class="p-2 sm:w-1/2 w-full">
                    <div class="relative">
                        <label for="firstName" class="leading-7 text-sm text-gray-600">Vorname *</label>
                        <input [formControlName]="'firstName'" type="text" id="firstName" name="firstName"
                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                    </div>
                </div>
                <div class="p-2 sm:w-1/2 w-full">
                    <div class="relative">
                        <label for="lastName" class="leading-7 text-sm text-gray-600">Nachname *</label>
                        <input [formControlName]="'lastName'" type="text" id="lastName" name="lastName"
                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                    </div>
                </div>
                <div class="p-2 sm:w-1/2 w-full">
                    <div class="relative">
                        <label for="email" class="leading-7 text-sm text-gray-600">E-Mail-Adresse *</label>
                        <input [formControlName]="'email'" type="email" id="email" name="email"
                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                    </div>
                </div>
                <div class="p-2 sm:w-1/2 w-full">
                    <div class="relative">
                        <label for="phone" class="leading-7 text-sm text-gray-600">Telefon</label>
                        <input [formControlName]="'phone'" type="text" id="phone" name="phone"
                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                    </div>
                </div>
                <div class="p-2 w-full mb-4">
                    <div class="relative">
                        <label for="message" class="leading-7 text-sm text-gray-600">Nachricht *</label>
                        <textarea [formControlName]="'message'" id="message" name="message"
                                  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                    </div>
                </div>

                <!--<h2 class="sm:text-xl p-2 text-lg font-medium title-font mb-4 text-gray-900">What can we help you
                    with?</h2>
                <div class="p-2 w-full">
                    &lt;!&ndash;<div class="flex items-center mb-4">
                        <input [formControlName]="'erp'" id="erp" aria-describedby="erp" type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="erp" class="ml-3 text-gray-900 dark:text-gray-300">ERP: Odoo set up and
                            customization</label>
                        &lt;!&ndash;                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-700 hover:underline dark:text-indigo-400">terms and conditions</a></label>&ndash;&gt;
                    </div>&ndash;&gt;
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'allocation'" id="allocation" aria-describedby="allocation"
                               type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="allocation" class="ml-3 text-gray-900 dark:text-gray-300">Development: software
                            development for your project</label>
                        &lt;!&ndash;                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-700 hover:underline dark:text-indigo-400">terms and conditions</a></label>&ndash;&gt;
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'funding'" id="funding" aria-describedby="funding" type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="funding" class="ml-3 text-gray-900 dark:text-gray-300">Funding: application of state
                            subsidies for digitalization</label>
                        &lt;!&ndash;                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-700 hover:underline dark:text-indigo-400">terms and conditions</a></label>&ndash;&gt;
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'consulting'" id="consulting" aria-describedby="consulting"
                               type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="consulting" class="ml-3 text-gray-900 dark:text-gray-300">Consulting: insights from
                            our experts about your project</label>
                        &lt;!&ndash;                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-700 hover:underline dark:text-indigo-400">terms and conditions</a></label>&ndash;&gt;
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'srs'" id="srs" aria-describedby="srs" type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="srs" class="ml-3 text-gray-900 dark:text-gray-300">Planning: writing / reviewing of
                            software requirements</label>
                        &lt;!&ndash;                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-700 hover:underline dark:text-indigo-400">terms and conditions</a></label>&ndash;&gt;
                    </div>
                    &lt;!&ndash;<div class="flex mb-4">
                        <div class="flex items-center h-5">
                            <input id="shipping-2" aria-describedby="shipping-2" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        </div>
                        <div class="ml-3 text-sm">
                            <label for="shipping-2" class="font-medium text-gray-900 dark:text-gray-300">Free shipping via Flowbite</label>
                            <div class="text-gray-500 dark:text-gray-300"><span class="text-xs font-normal">For orders shipped from Flowbite from <span class="font-medium">€ 25</span> in books or <span>€ 29</span> on other categories</span></div>
                        </div>
                    </div>

                    <div class="flex items-center">
                        <input id="international-shipping-disabled" aria-describedby="international-shipping-disabled" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" disabled>
                        <label for="international-shipping-disabled" class="ml-3 text-sm font-medium text-gray-300 dark:text-gray-500">Eligible for international shipping (disabled)</label>
                    </div>&ndash;&gt;
                </div>
                <h2 class="sm:text-xl p-2 text-lg font-medium title-font mb-4 text-gray-900">Which solutions do you
                    need?</h2>
                <div class="p-2 w-full">
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'saas'" id="saas" aria-describedby="saas" type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="saas" class="ml-3 text-gray-900 dark:text-gray-300">SaaS: mobile apps, APIs,
                            automation,
                            payment solutions</label>
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'ai'" id="ai" aria-describedby="ai" type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="ai" class="ml-3 text-gray-900 dark:text-gray-300">AI: smart product enhancement or
                            new
                            service</label>
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'blockchain'" id="blockchain" aria-describedby="blockchain"
                               type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="blockchain" class="ml-3 text-gray-900 dark:text-gray-300">Blockchain: DAOs, DeFi,
                            smart
                            contracts, NFTs, supply chain</label>
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'others'" id="others" aria-describedby="others" type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="others" class="ml-3 text-gray-900 dark:text-gray-300">Others</label>
                    </div>

                </div>-->

                <!--<h2 class="sm:text-xl p-2 w-full text-lg font-medium title-font mb-4 text-gray-900">What is your
                    budget?</h2>

                <div class="p-2 w-full">
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'budget'" id="budget-20" type="radio" name="budget" value="6"
                               class="w-4 h-4 text-indigo-700 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:focus:bg-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                               aria-labelledby="budget-20" aria-describedby="budget-20">
                        <label for="budget-20" class="block ml-2 text-gray-900 dark:text-gray-300">
                            Up to 20.000 €
                        </label>
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'budget'" id="budget-50" type="radio" name="budget" value="7"
                               class="w-4 h-4 text-indigo-700 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:focus:bg-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                               aria-labelledby="budget-50" aria-describedby="budget-50">
                        <label for="budget-50" class="block ml-2 text-gray-900 dark:text-gray-300">
                            20.000 € - 50.000 €
                        </label>
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'budget'" id="budget-100" type="radio" name="budget" value="8"
                               class="w-4 h-4 text-indigo-700 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                               aria-labelledby="budget-100" aria-describedby="budget-100">
                        <label for="budget-100" class="block ml-2 text-gray-900 dark:text-gray-300">
                            50.000 € - 100.000 €
                        </label>
                    </div>
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'budget'" id="budget-more" type="radio" name="budget" value="9"
                               class="w-4 h-4 text-indigo-700 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                               aria-labelledby="budget-more" aria-describedby="budget-more">
                        <label for="budget-more" class="block ml-2 text-gray-900 dark:text-gray-300">
                            More than 100.000 €
                        </label>
                    </div>
                </div>-->

                <div class="p-2 w-full">
                    <div class="flex items-center mb-4">
                        <input [formControlName]="'agreement'" id="agreement" aria-describedby="agreement"
                               type="checkbox"
                               class="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="agreement" class="ml-3 text-gray-900 dark:text-gray-300">Ich habe die  <a
                            class="text-indigo-700 hover:underline" href="https://lukaz.ai/assets/docs/Datenschutzerklaerung.pdf"
                            target="_blank">Datenschutzerklärung</a> gelesen.</label>
                        <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-700 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                    </div>
                </div>

                <div class="p-2 w-full mt-6 mb-10">
                    <button (click)="sendContactForm()"
                            id="contact-send"
                            class="click-tag flex mx-auto text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
                        Absenden
                    </button>
                </div>
            </form>
        </div>
    </div>
</section>

<section class="relative text-gray-600 bg-gray-100 body-font md:bg-connections-white-inv md:bg-top">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900">Kontaktieren Sie uns</h1>
            <!--            <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify.</p>-->
        </div>
        <div class="lg:w-1/2 md:w-2/3 mx-auto mb-10 max-w-sm">
            <div class="flex flex-wrap -m-2">
                <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                    <a class="text-indigo-700">hallo@lukaz.ai</a>
                </div>
                <div class="p-2 w-full pt-8 mt-6 border-t border-gray-200 text-center">
                    <a class="text-indigo-700">+49 89 5419 4791</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="relative text-gray-100 dark:text-gray-100 bg-gray-900 dark:bg-gray-900 body-font" id="team">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-900 dark:text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col w-full mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4">Ein Team, auf welches wir stolz sind</h1>
            <p class="max-w-prose leading-relaxed text-base">
                Unsere Mission ist es, die Welt durch die Nutzung der Kräfte der künstlichen Intelligenz zu einem
                besseren Ort zu machen, damit Menschen und Organisationen ihr volles Potenzial ausschöpfen können.
            </p>
        </div>
        <div class="flex flex-wrap -m-4 mb-12 items-center sm:justify-start justify-center text-center sm:text-left">
            <div class="p-4 lg:w-1/2">
                <div
                    class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                         src="assets/img/people/Max.png">
                    <div class="flex-grow sm:pl-8">
                        <h2 class="title-font font-medium text-lg">
                            <a class="click-tag hover:underline" href="https://www.linkedin.com/in/max-daeweritz"
                               target="_blank">
                                Maximilian Däweritz
                            </a>
                        </h2>

                        <h3 class="text-gray-500 dark:text-gray-500 mb-4">CEO & Co-Founder</h3>
                        <p class="mb-4"><span class="italic">TU München</span></p>

                        <div class="hidden sm:inline-flex">
                            <a class="text-gray-500" href="https://www.linkedin.com/in/max-daeweritz" target="_blank">
                                <svg id="Group_1282" data-name="Group 1282" xmlns="http://www.w3.org/2000/svg"
                                     width="76.624" height="65.326" viewBox="0 0 76.624 65.326"
                                     style="height: 50%;width: auto">
                                    <path id="Path_2525" data-name="Path 2525"
                                          d="M1165,274.515a1.2,1.2,0,0,0,1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025,1.291,2.029h.724l-1.389-2.1Zm-.783-.472h-.785V272.45h.995c.514,0,1.1.084,1.1.757,0,.774-.593.836-1.314.836"
                                          transform="translate(-1092.136 -213.406)" fill="#0a66c2"/>
                                    <path id="Path_2520" data-name="Path 2520"
                                          d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
                                          transform="translate(-903.776 -57.355)" fill="#0a66c2"/>
                                    <path id="Path_2526" data-name="Path 2526"
                                          d="M1156.525,264.22a4.418,4.418,0,1,0,.085,0h-.085m0,8.33a3.874,3.874,0,1,1,3.809-3.938c0,.022,0,.043,0,.065a3.791,3.791,0,0,1-3.708,3.871h-.1"
                                          transform="translate(-1084.362 -207.809)" fill="#0a66c2"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2">
                <div
                    class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                         src="assets/img/people/Rafa.png">
                    <div class="flex-grow sm:pl-8">
                        <h2 class="title-font font-medium text-lg">
                            <a class="click-tag hover:underline" href="https://www.linkedin.com/in/rafael-muenchen"
                               target="_blank">
                                Rafael München
                            </a>
                        </h2>
                        <h3 class="text-gray-500 dark:text-gray-500 mb-4">CTO & Co-Founder</h3>
                        <p class="mb-4"><span class="italic">LMU München</span>
                        </p>
                        <div class="hidden sm:inline-flex">
                            <a class="text-gray-500" href="https://www.linkedin.com/in/rafael-muenchen" target="_blank">
                                <svg id="Group_1282" data-name="Group 1282" xmlns="http://www.w3.org/2000/svg"
                                     width="76.624" height="65.326" viewBox="0 0 76.624 65.326"
                                     style="height: 50%;width: auto">
                                    <path id="Path_2525" data-name="Path 2525"
                                          d="M1165,274.515a1.2,1.2,0,0,0,1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025,1.291,2.029h.724l-1.389-2.1Zm-.783-.472h-.785V272.45h.995c.514,0,1.1.084,1.1.757,0,.774-.593.836-1.314.836"
                                          transform="translate(-1092.136 -213.406)" fill="#0a66c2"/>
                                    <path id="Path_2520" data-name="Path 2520"
                                          d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
                                          transform="translate(-903.776 -57.355)" fill="#0a66c2"/>
                                    <path id="Path_2526" data-name="Path 2526"
                                          d="M1156.525,264.22a4.418,4.418,0,1,0,.085,0h-.085m0,8.33a3.874,3.874,0,1,1,3.809-3.938c0,.022,0,.043,0,.065a3.791,3.791,0,0,1-3.708,3.871h-.1"
                                          transform="translate(-1084.362 -207.809)" fill="#0a66c2"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap -m-4 mb-12 items-center sm:justify-start justify-center text-center sm:text-left">
            <div class="p-4 lg:w-1/2">
                <div
                    class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                         src="assets/img/people/MaxB.png">
                    <div class="flex-grow sm:pl-8">
                        <h2 class="title-font font-medium text-lg">
                            <a class="click-tag hover:underline" href="https://www.linkedin.com/in/maximilian-bustos-seibert"
                               target="_blank">
                                Maximilian Bustos
                            </a>
                        </h2>
                        <h3 class="text-gray-500 dark:text-gray-500 mb-4">COO & Shareholder</h3>
                        <p class="mb-4"><span class="italic">TU München</span></p>
                        <div class="hidden sm:inline-flex">
                            <a class="text-gray-500" href="https://www.linkedin.com/in/maximilian-bustos-seibert" target="_blank">
                                <svg id="Group_1282" data-name="Group 1282" xmlns="http://www.w3.org/2000/svg"
                                     width="76.624" height="65.326" viewBox="0 0 76.624 65.326"
                                     style="height: 50%;width: auto">
                                    <path id="Path_2525" data-name="Path 2525"
                                          d="M1165,274.515a1.2,1.2,0,0,0,1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025,1.291,2.029h.724l-1.389-2.1Zm-.783-.472h-.785V272.45h.995c.514,0,1.1.084,1.1.757,0,.774-.593.836-1.314.836"
                                          transform="translate(-1092.136 -213.406)" fill="#0a66c2"/>
                                    <path id="Path_2520" data-name="Path 2520"
                                          d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
                                          transform="translate(-903.776 -57.355)" fill="#0a66c2"/>
                                    <path id="Path_2526" data-name="Path 2526"
                                          d="M1156.525,264.22a4.418,4.418,0,1,0,.085,0h-.085m0,8.33a3.874,3.874,0,1,1,3.809-3.938c0,.022,0,.043,0,.065a3.791,3.791,0,0,1-3.708,3.871h-.1"
                                          transform="translate(-1084.362 -207.809)" fill="#0a66c2"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2">
                <div
                    class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                         src="assets/img/people/Paul.png">
                    <div class="flex-grow sm:pl-8">
                        <h2 class="title-font font-medium text-lg">
                            <a class="click-tag hover:underline" href="https://www.linkedin.com/in/paul-volkhardt-518967204"
                               target="_blank">
                                Paul Volkhardt
                            </a>
                        </h2>
                        <h3 class="text-gray-500 dark:text-gray-500 mb-4">CFO & Shareholder</h3>
                        <p class="mb-4"><span class="italic">Munich Business School</span></p>
                        <div class="hidden sm:inline-flex">
                            <a class="text-gray-500" href="https://www.linkedin.com/in/paul-volkhardt-518967204" target="_blank">
                                <svg id="Group_1282" data-name="Group 1282" xmlns="http://www.w3.org/2000/svg"
                                     width="76.624" height="65.326" viewBox="0 0 76.624 65.326"
                                     style="height: 50%;width: auto">
                                    <path id="Path_2525" data-name="Path 2525"
                                          d="M1165,274.515a1.2,1.2,0,0,0,1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025,1.291,2.029h.724l-1.389-2.1Zm-.783-.472h-.785V272.45h.995c.514,0,1.1.084,1.1.757,0,.774-.593.836-1.314.836"
                                          transform="translate(-1092.136 -213.406)" fill="#0a66c2"/>
                                    <path id="Path_2520" data-name="Path 2520"
                                          d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
                                          transform="translate(-903.776 -57.355)" fill="#0a66c2"/>
                                    <path id="Path_2526" data-name="Path 2526"
                                          d="M1156.525,264.22a4.418,4.418,0,1,0,.085,0h-.085m0,8.33a3.874,3.874,0,1,1,3.809-3.938c0,.022,0,.043,0,.065a3.791,3.791,0,0,1-3.708,3.871h-.1"
                                          transform="translate(-1084.362 -207.809)" fill="#0a66c2"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap -m-4 mb-12 items-center sm:justify-start justify-center text-center sm:text-left">
            <div class="p-4 lg:w-1/2">
                <div
                    class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                         src="assets/img/people/Eduardo.png">
                    <div class="flex-grow sm:pl-8">
                        <h2 class="title-font font-medium text-lg">
                            <a class="click-tag hover:underline" href="https://www.linkedin.com/in/eduardofilipe-thestarchaser"
                               target="_blank">
                                Eduardo Filipe
                            </a>
                        </h2>

                        <h3 class="text-gray-500 dark:text-gray-500 mb-4">Head of Development</h3>
                        <div class="hidden sm:inline-flex">
                            <a class="text-gray-500" href="https://www.linkedin.com/in/eduardofilipe-thestarchaser" target="_blank">
                                <svg id="Group_1282" data-name="Group 1282" xmlns="http://www.w3.org/2000/svg"
                                     width="76.624" height="65.326" viewBox="0 0 76.624 65.326"
                                     style="height: 50%;width: auto">
                                    <path id="Path_2525" data-name="Path 2525"
                                          d="M1165,274.515a1.2,1.2,0,0,0,1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025,1.291,2.029h.724l-1.389-2.1Zm-.783-.472h-.785V272.45h.995c.514,0,1.1.084,1.1.757,0,.774-.593.836-1.314.836"
                                          transform="translate(-1092.136 -213.406)" fill="#0a66c2"/>
                                    <path id="Path_2520" data-name="Path 2520"
                                          d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
                                          transform="translate(-903.776 -57.355)" fill="#0a66c2"/>
                                    <path id="Path_2526" data-name="Path 2526"
                                          d="M1156.525,264.22a4.418,4.418,0,1,0,.085,0h-.085m0,8.33a3.874,3.874,0,1,1,3.809-3.938c0,.022,0,.043,0,.065a3.791,3.791,0,0,1-3.708,3.871h-.1"
                                          transform="translate(-1084.362 -207.809)" fill="#0a66c2"/>
                                </svg>
                            </a>
                        </div>
                        <p class="mb-4"><span class="italic">&nbsp;</span></p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2">
                <div
                    class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <!--<img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                         src="assets/img/people/Lu.png">
                    <div class="flex-grow sm:pl-8">
                        <h2 class="title-font font-medium text-lg">
                            <a class="click-tag hover:underline" href="https://www.linkedin.com/in/luana-de-ara%C3%BAjo-810032218"
                               target="_blank">
                                Luana Pagnoncelli
                            </a>
                        </h2>
                        <h3 class="text-gray-500 dark:text-gray-500 mb-4">Account Manager</h3>
                        <div class="hidden sm:inline-flex">
                            <a class="text-gray-500" href="https://www.linkedin.com/in/luana-de-ara%C3%BAjo-810032218" target="_blank">
                                <svg id="Group_1282" data-name="Group 1282" xmlns="http://www.w3.org/2000/svg"
                                     width="76.624" height="65.326" viewBox="0 0 76.624 65.326"
                                     style="height: 50%;width: auto">
                                    <path id="Path_2525" data-name="Path 2525"
                                          d="M1165,274.515a1.2,1.2,0,0,0,1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025,1.291,2.029h.724l-1.389-2.1Zm-.783-.472h-.785V272.45h.995c.514,0,1.1.084,1.1.757,0,.774-.593.836-1.314.836"
                                          transform="translate(-1092.136 -213.406)" fill="#0a66c2"/>
                                    <path id="Path_2520" data-name="Path 2520"
                                          d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
                                          transform="translate(-903.776 -57.355)" fill="#0a66c2"/>
                                    <path id="Path_2526" data-name="Path 2526"
                                          d="M1156.525,264.22a4.418,4.418,0,1,0,.085,0h-.085m0,8.33a3.874,3.874,0,1,1,3.809-3.938c0,.022,0,.043,0,.065a3.791,3.791,0,0,1-3.708,3.871h-.1"
                                          transform="translate(-1084.362 -207.809)" fill="#0a66c2"/>
                                </svg>
                            </a>
                        </div>-->
                    <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                         src="assets/img/people/Talita.png">
                    <div class="flex-grow sm:pl-8">
                        <h2 class="title-font font-medium text-lg">
                            <a class="click-tag hover:underline" href="https://www.linkedin.com/in/talitanogueirasaraiva"
                               target="_blank">
                                Talita Nogueira
                            </a>
                        </h2>
                        <h3 class="text-gray-500 dark:text-gray-500 mb-4">Head of Quality Assurance</h3>
                        <div class="hidden sm:inline-flex">
                            <a class="text-gray-500" href="https://www.linkedin.com/in/talitanogueirasaraiva" target="_blank">
                                <svg id="Group_1282" data-name="Group 1282" xmlns="http://www.w3.org/2000/svg"
                                     width="76.624" height="65.326" viewBox="0 0 76.624 65.326"
                                     style="height: 50%;width: auto">
                                    <path id="Path_2525" data-name="Path 2525"
                                          d="M1165,274.515a1.2,1.2,0,0,0,1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025,1.291,2.029h.724l-1.389-2.1Zm-.783-.472h-.785V272.45h.995c.514,0,1.1.084,1.1.757,0,.774-.593.836-1.314.836"
                                          transform="translate(-1092.136 -213.406)" fill="#0a66c2"/>
                                    <path id="Path_2520" data-name="Path 2520"
                                          d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
                                          transform="translate(-903.776 -57.355)" fill="#0a66c2"/>
                                    <path id="Path_2526" data-name="Path 2526"
                                          d="M1156.525,264.22a4.418,4.418,0,1,0,.085,0h-.085m0,8.33a3.874,3.874,0,1,1,3.809-3.938c0,.022,0,.043,0,.065a3.791,3.791,0,0,1-3.708,3.871h-.1"
                                          transform="translate(-1084.362 -207.809)" fill="#0a66c2"/>
                                </svg>
                            </a>
                        </div>
                        <p class="mb-4"><span class="italic">&nbsp;</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
