import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Router} from "@angular/router";

@Component({
    selector: 'tekr-home-de',
    templateUrl: './home-de.component.html',
    styleUrls: ['./home-de.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeDeComponent implements OnInit {

    constructor(private router: Router, private viewportScroller: ViewportScroller) {
    }

    ngOnInit(): void {
        localStorage.setItem('refreshed', 'false')

        if (localStorage.getItem('lang') === 'en') {
            this.router.navigate(['/'])
        }
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }
}
