import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ContactComponent} from './contact/contact.component';
import {ImprintComponent} from './imprint/imprint.component';
import {FeaturesComponent} from "./features/features.component";
import {UseCasesComponent} from "./use-cases/use-cases.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {CookiePolicyComponent} from "./cookie-policy/cookie-policy.component";
import {TermsOfUseComponent} from "./terms-of-use/terms-of-use.component";
import {FeaturesDeComponent} from "./features-de/features-de.component";
import {UseCasesDeComponent} from "./use-cases-de/use-cases-de.component";
import {HomeDeComponent} from "./home-de/home-de.component";
import {ContactDeComponent} from "./contact-de/contact-de.component";
import {ImprintDeComponent} from "./imprint-de/imprint-de.component";

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            title: 'Lukaz - Your AI tool for study and work',
            description: 'Flexible, context-based Your AI tool for study and work. Our LLM-driven platform allows end-users and organizations to generate and edit textual content for a variety of tasks, in a sustainable and cost-efficient way.',
            ogTitle: 'Lukaz - Your AI tool for study and work',
        }
    },
    {
        path: 'de',
        component: HomeDeComponent,
        data: {
            title: 'Lukaz - Das KI-Tool zum Lernen und Arbeiten',
            description: 'Flexible, context-based Your AI tool for study and work. Our LLM-driven platform allows end-users and organizations to generate and edit textual content for a variety of tasks, in a sustainable and cost-efficient way.',
            ogTitle: 'Lukaz - Your AI tool for study and work',
        }
    },
    {
        path: 'features',
        component: FeaturesComponent,
        data: {
            title: 'Your AI tool for study and work - Lukaz',
            description: 'You know the sources, Lukaz knows the answers. We help people and products grow by generating amazing content in no time. Safely automate prompt engineering based on your own trusted references.',
            ogTitle: 'Your AI tool for study and work - Lukaz',
        }
    },
    {
        path: 'de/features',
        component: FeaturesDeComponent,
        data: {
            title: 'Das KI-Tool zum Lernen und Arbeiten - Lukaz',
            description: 'You know the sources, Lukaz knows the answers. We help people and products grow by generating amazing content in no time. Safely automate prompt engineering based on your own trusted references.',
            ogTitle: 'Your AI tool for study and work - Lukaz',
        }
    },
    {
        path: 'use-cases',
        component: UseCasesComponent,
        data: {
            title: 'Your AI tool for study and work - Lukaz',
            description: 'An anything-to-anything text transformer. From short social media posts or youtube scripts to research papers or entire books, Lukaz can be used for many different content generation tasks.',
            ogTitle: 'Your AI tool for study and work - Lukaz',
        }
    },
    {
        path: 'de/use-cases',
        component: UseCasesDeComponent,
        data: {
            title: 'Das KI-Tool zum Lernen und Arbeiten - Lukaz',
            description: 'An anything-to-anything text transformer. From short social media posts or youtube scripts to research papers or entire books, Lukaz can be used for many different content generation tasks.',
            ogTitle: 'Your AI tool for study and work - Lukaz',
        }
    },
    {
        path: 'contact',
        component: ContactComponent,
        data: {
            title: 'Contact - Lukaz',
            description: 'Ready to discuss about your project? You may contact us via chat, Whatsapp, email or just send us a message. Let us build your innovative products with state-of-the-art AI software.',
            ogTitle: 'Contact - tekr',
        }
    },
    {
        path: 'de/contact',
        component: ContactDeComponent,
        data: {
            title: 'Kontakt - Lukaz',
            description: 'Ready to discuss about your project? You may contact us via chat, Whatsapp, email or just send us a message. Let us build your innovative products with state-of-the-art AI software.',
            ogTitle: 'Contact - tekr',
        }
    },
    {
        path: 'imprint',
        component: ImprintComponent,
        data: {
            title: 'Imprint - Lukaz',
            description: 'Imprint Lukaz.',
            ogTitle: 'Imprint - Lukaz',
        }
    },
    {
        path: 'de/imprint',
        component: ImprintDeComponent,
        data: {
            title: 'Impressum - Lukaz',
            description: 'Imprint Lukaz.',
            ogTitle: 'Imprint - Lukaz',
        }
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
            title: 'Privacy Policy - Lukaz',
            description: 'Privacy Policy Lukaz.',
            ogTitle: 'Privacy Policy - Lukaz',
        }
    },
    {
        path: 'cookie-policy',
        component: CookiePolicyComponent,
        data: {
            title: 'Cookie Policy - Lukaz',
            description: 'Cookie Policy Lukaz.',
            ogTitle: 'Cookie Policy - Lukaz',
        }
    },
    {
        path: 'terms-of-use',
        component: TermsOfUseComponent,
        data: {
            title: 'Terms of Use - Lukaz',
            description: 'Terms of Use Lukaz.',
            ogTitle: 'Terms of Use - Lukaz',
        }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
