<section class="text-gray-600 bg-white dark:bg-gray-900 md:bg-work-purple md:bg-top body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-6/12 lg:w-6/12 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <!--<h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-2xl">
                We improve software productivity through artificial intelligence
            </h1>-->
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                Educational AI to Redefine Your Classroom
            </h1>
            <p class="mb-8 leading-relaxed max-w-prose">
                Lukaz is a customizable, secure platform designed to help educators gradually introduce AI technology
                to students, fostering engagement and innovation in an integrative environment.
            </p>
            <div class="container mx-auto flex flex-wrap flex-col md:flex-row gap-4">
                <div class="">
                    <a [routerLink]="'teaching'"
                       id="home-lm-org"
                       class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                        Learn more
                    </a>
                </div>
                <div class="">
                    <a href="https://lukaz.ai" target="_blank"
                       id="home-cta-org"
                       class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 rounded text-lg">
                        Create AI board
                    </a>
                </div>
            </div>

        </div>
        <div class="w-full md:w-6/12 lg:w-8/12 items-center text-center md:ml-12 mb-8">
            <img src="assets/img/screenshots/teaching-3.png" alt="Lukaz Board" class="hidden lg:block mx-auto"/>
            <img src="assets/img/screenshots/teaching-3-mobile.png" alt="Lukaz Board" class="lg:hidden mx-auto"/>
        </div>
    </div>

</section>

<section class="relative text-gray-100 bg-indigo-500 body-font md:bg-connections-white-inv md:bg-top">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-indigo-500 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:max-w-lg lg:w-full md:w-1/3 w-5/6">

        </div>
        <div
            class="lg:flex-grow md:w-2/3 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="text-gray-100 dark:text-gray-100 title-font sm:text-4xl text-3xl mb-4 font-medium max-w-md">
                Over 600 German Schools Are Using Lukaz
            </h1>
            <p class="text-gray-100 dark:text-gray-100 mb-8 leading-relaxed max-w-prose">
                Lukaz supports teachers in reducing their classroom workload by providing a secure,
                AI-driven platform tailored for educators and students alike.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <a [routerLink]="'teaching'"
                   id="home-lm-dev"
                   class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                    Learn more
                </a>
                <a href="https://lukaz.ai" target="_blank"
                   id="home-cta-dev"
                   class="ml-2 md:ml-4 inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                    Register
                </a>
            </div>
        </div>
    </div>

</section>

<section class="relative text-gray-600 bg-gray-100 md:bg-top body-font" id="press">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>

    <div class="container px-5 pt-24 pb-12 mx-auto">
        <div class="flex flex-col w-full">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Highlight of Didacta 2024</h1>

            <p class="mb-8 leading-relaxed max-w-prose">
                Lukaz was proudly showcased at Didacta 2024 in Cologne,
                where German public TV network
                <a class="underline" href="https://www.rtl.de/cms/messe-didacta-setzt-kuenstliche-intelligenz-in-schulen-auf-die-agenda-5077601.html" target="_blank">
                    RTL highlighted our innovative AI solution for schools
                </a>.<br/><br/>
                CEO Maximilian Däweritz demonstrated how Lukaz is shaping the future of education:
            </p>
        </div>

        <div class="max-w-prose mb-12">
            <a
               href="https://www.rtl.de/cms/messe-didacta-setzt-kuenstliche-intelligenz-in-schulen-auf-die-agenda-5077601.html" target="_blank">
                <img src="assets/img/press/Didacta-2024.png" alt="Didacta 2024" class="rounded-xl">
            </a>
        </div>

        <!--<div class="w-full mb-24">
            <video
                loop
                muted
                controls
                class="rounded-2xl"
            >
                <source
                    src="assets/img/press/Didacta-2024.mp4"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>-->

    </div>
</section>

<!--
<section class="relative flex items-center justify-center h-screen overflow-hidden">
    &lt;!&ndash;<div
        class="relative z-30 p-5 text-2xl text-white bg-purple-300 bg-opacity-50 rounded-xl"
    >
        Welcome to my site!
    </div>&ndash;&gt;
    <video
        autoplay
        loop
        muted
        controls
        class="absolute z-10 w-auto min-w-full min-h-full max-w-none"
    >
        <source
            src="assets/img/press/Didacta-2024.mp4"
            type="video/mp4"
        />
        Your browser does not support the video tag.
    </video>
</section>-->

<tekr-contact-section></tekr-contact-section>

<section class="relative text-gray-600 bg-gray-100 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto flex px-5 pt-24 md:flex-row flex-col items-center">
        <div class="flex flex-col w-full mb-10 text-center md:text-left">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                Trusted by Industry Leaders
            </h1>
            <p class="max-w-prose mb-8">
                Our partnerships with global and regional innovators drive success across industries.
            </p>
            <!--<h2 class="mx-auto max-w-prose sm:text-2xl text-xl font-medium title-font mb-4 text-gray-900">
                Some of our partners:
            </h2>-->
        </div>
    </div>

    <div class="container mx-auto flex px-5 pb-24 md:flex-row flex-col items-center">
        <div class="flex flex-col md:flex-row w-full mb-10 gap-8">
            <div class="text-center md:text-left">
                <img src="assets/img/partners/terra.png" alt="Terra" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Wortmann AG
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/microsoft.png" alt="Microsoft" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Microsoft Corporation
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/eduplaces.png" alt="Eduplaces" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Eduplaces GmbH
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/iserv.png" alt="iServ" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    iServ GmbH
                </h2>
            </div>
        </div>
    </div>
</section>

<section class="relative bg-gray-900 md:bg-top body-font text-center" id="testimonials">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>

    <div class="container px-5 py-24 mx-auto text-center">
        <div class="max-w-prose text-center mx-auto mb-12">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        The easy integration of Tekr's innovative AI solutions into our software package, partnered with
                        Microsoft, showcases their value.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">ANDREAS BARKOWSKI</h2>
                    <p class="text-gray-400 mb-8">Sales Director of Wortmann AG</p>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        Tekr helped us build the core of our AI-powered software that improves the customer experience
                        for giants like Audi, P&G and Coop.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">MARC FUNK</h2>
                    <p class="text-gray-400 mb-8">CEO of Frontnow GmbH</p>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        MyDose is today a successful wellness app thanks to great innovations, some of them brought by Tekr's highly qualified team.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">GUSTAVO MARION</h2>
                    <p class="text-gray-400 mb-8">CEO of MyDose LLC</p>
                </ng-template>
            </owl-carousel-o>

        </div>
    </div>
</section>

<tekr-pipeline-section></tekr-pipeline-section>

<!--
<section class="relative text-gray-600 bg-gray-100 md:bg-top body-font" id="press">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>

    <div class="container px-5 pt-24 pb-12 mx-auto">
        <div class="flex flex-col w-full">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Highlight of Didacta 2024</h1>

            <p class="mb-8 leading-relaxed max-w-prose">
                Lukaz was proudly showcased at Didacta 2024 in Cologne,
                where German public TV network
                <a class="underline" href="https://www.rtl.de/cms/messe-didacta-setzt-kuenstliche-intelligenz-in-schulen-auf-die-agenda-5077601.html" target="_blank">
                    RTL highlighted our innovative AI solution for schools
                </a>.<br/><br/>
                CEO Maximilian Däweritz demonstrated how Lukaz is shaping the future of education:
            </p>
        </div>

        <div class="max-w-prose mb-12">
            <a
                href="https://www.rtl.de/cms/messe-didacta-setzt-kuenstliche-intelligenz-in-schulen-auf-die-agenda-5077601.html" target="_blank">
                <img src="assets/img/press/Didacta-2024.png" alt="Didacta 2024" class="rounded-xl">
            </a>
        </div>

        &lt;!&ndash;<div class="w-full mb-24">
            <video
                loop
                muted
                controls
                class="rounded-2xl"
            >
                <source
                    src="assets/img/press/Didacta-2024.mp4"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>&ndash;&gt;

    </div>
</section>

<section class="relative text-gray-600 bg-gray-100 md:bg-connections-black-inv md:bg-top body-font" id="features-fourth">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-gray-900 text-center text-2xl font-medium title-font mb-2 text-gray-100">
                        Most flexible<br/>
                        AI editor to date
                    </h1>
                    &lt;!&ndash;                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>&ndash;&gt;
                    &lt;!&ndash;<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>&ndash;&gt;
                </div>
            </div>
        </div>
    </div>
    <div class="container px-5 py-5 pb-36 mx-auto flex flex-wrap">
        <div class="flex flex-wrap -m-4">
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Chat and prompt modes
                        </h2>
                        <p class="leading-relaxed text-base">
                            Enhance the prompt engineering skills of your users while letting them interact
                            with a board in two different prompting perspectives.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            HTML preview
                        </h2>
                        <p class="leading-relaxed text-base">
                            Visualize your generated pure HTML code or even HTML + TailwindCSS. Further transform
                            UX elements with developer templates.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Syntax highlighting
                        </h2>
                        <p class="leading-relaxed text-base">
                            Generate and edit code in different programming languages in the same board.
                            Transform or regenerate your code with one click.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                            Free API access
                        </h2>
                        <p class="leading-relaxed text-base">
                            Build state-of-the-art AI software or integrate any existing system with our robust API.
                            Get support from our community.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->
